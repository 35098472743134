/* Shadow 0dp */
.shadow-0 {
  box-shadow: none;
}
/* Shadow 1dp */
.shadow-1 {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
/* Shadow 2dp */
.shadow-2 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
/* Shadow 3dp */
.shadow-3 {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}
/* Shadow 4dp */
.shadow-4 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
/* Shadow 6dp */
.shadow-6 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
/* Shadow 8dp */
.shadow-8 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
/* Shadow 9dp */
.shadow-9 {
  box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
}
/* Shadow 12dp */
.shadow-12 {
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}
/* Shadow 16dp */
.shadow-16 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
/* Shadow 24dp */
.shadow-24 {
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}
body {
  font-size: 16px;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  color: #333;
  box-sizing: border-box;
}
body #header {
  display: flex;
  flex-direction: row;
  padding: 1rem 20px 4px;
}
body #header .logo {
  flex: 0 0 auto;
  width: 220px;
  height: 34px;
  background-image: url(components/logo.png);
}
body #header .spacer {
  flex: 1 1 auto;
}
body #header .userDetails {
  flex: 0 0 auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
body #header .userDetails:hover #userPermissions {
  visibility: visible;
}
body #header .userDetails .username {
  text-align: right;
  color: #777;
}
body #header .userDetails .username .name {
  margin-left: 0.3rem;
  color: #178;
  letter-spacing: 0.25px;
}
body #header .userDetails .version {
  text-align: right;
  color: #bdbdbd;
  font-style: italic;
}
body #header .userDetails #userPermissions {
  visibility: hidden;
  position: absolute;
  top: 1.25rem;
  right: 0;
  background-color: #fff;
  color: #66a326;
  padding: 1rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
body #header .userDetails #userPermissions svg {
  margin-right: 3rem;
}
body #header .userDetails #userPermissions .title {
  color: #777;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
body #header .userDetails #userPermissions .permission {
  font-weight: 300;
}
body #appTitle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: -0.07rem;
  padding: 4px 20px 16px;
}
body #nav {
  padding: 0 20px;
  margin: 0 20px;
  height: 42px;
  background-color: #88bd40;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid #83b53d;
}
body #nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  max-width: 960px;
  height: 42px;
}
body #nav ul li {
  cursor: pointer;
  color: #fff;
  padding: 0 1rem;
  height: 100%;
  border-left: 1px solid #88bd40;
  border-right: 1px solid #88bd40;
  line-height: 42px;
}
body #nav ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  height: 100%;
  line-height: 42px;
}
body #nav ul li:hover,
body #nav ul li.selected {
  background-color: #66a326;
  border-left-color: #619b24;
  border-right-color: #619b24;
}
body #content {
  border-top: 3px solid #ccc;
  margin: 0 20px;
  padding: 20px 0;
}
body h1 {
  color: #1c8794;
  letter-spacing: -0.03rem;
  font-size: 1.75rem;
  margin: 0;
  font-weight: 400;
}
body p {
  margin: 0;
}
body form {
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  width: 500px;
  margin-top: 1rem;
  padding: 1rem;
}
body form .row {
  display: flex;
  flex-direction: row;
  margin: 1rem;
  height: 34px;
  line-height: 34px;
}
body form .row .spacer {
  flex: 1 1 auto;
}
body form .row.left {
  justify-content: left;
}
body form .row.right {
  justify-content: right;
}
body form .row.center {
  justify-content: center;
}
body form .row .label {
  font-weight: bold;
  flex: 0 0 auto;
  width: 180px;
  text-align: right;
}
body form .row .control {
  flex: 1 0 auto;
  margin: 0 0 0 1rem;
  height: 34px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  color: #555;
  background-color: #fff;
}
body form .row .control .MuiTextField-root {
  width: 100%;
  height: 34px;
}
body form .row .control .MuiTextField-root .MuiInputBase-root {
  height: 100%;
  position: relative;
  top: 3px;
}
body form .row .control .MuiTextField-root .MuiInputBase-root input {
  text-indent: 0;
}
body form .row .control .MuiTextField-root .MuiInput-underline:before {
  border-bottom: none !important;
}
body form .row .control .MuiTextField-root .MuiInput-underline:after {
  border-bottom-color: #88bd40;
}
body form .row .control.no-border {
  border: none !important;
}
body form .row .control input {
  border: none;
  height: 32px;
  line-height: 32px;
  padding: 0 0.5rem;
  width: calc(100% - 16px);
  position: relative;
  outline: none !important;
  text-indent: 0rem;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-size: 1rem;
}
body form .row .control input:focus {
  outline: none !important;
}
body form .row .control select {
  outline: none !important;
  border: none;
  height: 32px;
  line-height: 32px;
  padding: 0 0.5rem;
  width: 100%;
  position: relative;
  top: -2px;
}
body form .btn {
  flex: 0 0 auto;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  color: #178;
  border: 1px solid #178;
  margin-right: 1rem;
}
body form .btn.primary {
  background-color: #dc712a;
  border-color: #cf6a27;
  color: #fff;
}
body form .btn:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}
body form .btn:hover.primary {
  background-color: #e08040;
  border-color: #d97736;
}
body form .btn svg {
  margin-right: 0.5rem;
}
body #searchWindow {
  display: flex;
  flex-direction: row;
}
body #searchWindow form {
  flex: 0 0 auto;
  margin-right: 2rem;
}
body table {
  margin-top: 0.5rem;
  width: 640px;
  border-collapse: collapse;
}
body table thead th,
body table thead td {
  text-align: left;
  padding: 0.25rem;
  border-bottom: 2px solid #94c452;
}
body table thead th:hover,
body table thead td:hover {
  cursor: pointer;
  border-bottom-color: #88bd40;
}
body table thead th:hover svg,
body table thead td:hover svg {
  opacity: 0.95;
}
body table thead th svg,
body table thead td svg {
  color: #178;
  opacity: 0.75;
  margin-left: 0.25rem;
}
body table tbody tr:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
body table tbody tr.no-hover:hover {
  background-color: #fff;
  cursor: default;
}
body table tbody tr td {
  text-align: left;
  padding: 0.5rem 0.25rem;
  border-bottom: 1px dashed #ccc;
}
#imageContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9997;
}
#imageContainer svg {
  color: #f3f3f3;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 1rem;
}
#imageContainer .fader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.75;
  background-color: #333;
  z-index: 9998;
}
#imageContainer .imageWindow {
  background-color: #222;
  padding: 1rem;
  width: 960px;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
#imageContainer .imageWindow .thumbnailSection {
  flex: 0 0 auto;
  width: 240px;
  padding: 40px 0 0;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  color: #f5f5f5;
  font-weight: 300;
}
#imageContainer .imageWindow .thumbnailSection img,
#imageContainer .imageWindow .thumbnailSection .loadingCheck {
  width: 220px;
  height: auto;
  min-height: calc(220px * 0.37);
  background: #fff;
  border: 1px solid #fff;
  margin-bottom: 1rem;
}
#imageContainer .imageWindow .thumbnailSection .loadingCheck svg {
  height: calc((220px * 0.37) - 32px);
  width: calc((220px * 0.37) - 32px);
  color: #88bd40;
  opacity: 0.65;
  margin-left: -0.5rem;
}
#imageContainer .imageWindow .imageSection {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
#imageContainer .imageWindow .imageSection .windowControls {
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
}
#imageContainer .imageWindow .imageSection .windowControls svg {
  cursor: pointer;
}
#imageContainer .imageWindow .imageSection .windowControls svg:hover {
  color: #fff;
}
#imageContainer .imageWindow .imageSection img,
#imageContainer .imageWindow .imageSection .loadingCheck {
  width: 680px;
  height: auto;
  min-height: 300px;
  border: 1px solid #fff;
  margin: 0.5rem 0 1rem;
  background: #fff;
}
#imageContainer .imageWindow .imageSection .loadingCheck svg {
  height: 200px;
  width: 200px;
  color: #88bd40;
  opacity: 0.65;
}
.depositDetailRow {
  background-color: #e7f4d6;
}
.depositDetailRow td {
  font-style: oblique;
}
.footer {
  margin: 1rem;
  padding: 1rem;
  border-top: 1px solid #e3e3e3;
  opacity: 0.5;
}
.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  opacity: 0.85;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5rem;
  z-index: 9999;
}
.loadingContainer div {
  text-align: center;
}
.loadingContainer img {
  width: 300px;
  height: 200px;
}
.loadingContainer img.error {
  width: 150px;
  height: 150px;
}
.loadingContainer .btn {
  flex: 0 0 auto;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  color: #178;
  border: 1px solid #178;
  margin-right: 1rem;
}
.loadingContainer .btn.primary {
  background-color: #5f842d;
  border-color: #517126;
  color: #fff;
}
.loadingContainer .btn:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}
.loadingContainer .btn:hover.primary {
  background-color: #6d9733;
  border-color: #5f842d;
}
.loadingContainer .btn svg {
  margin-right: 0.5rem;
}
.Toastify__toast--error {
  background-color: #fd1651 !important;
}
.Toastify__toast--success {
  background-color: #89bd41 !important;
}
.Toastify__toast {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2) !important;
}
.Toastify__toast-container {
  width: auto !important;
}
.Toastify__toast-container--top-center {
  margin-left: 0 !important;
  transform: translate(-50%, 0) !important;
}
.Toastify__toast-body {
  padding: 1rem;
}
.errorMessage {
  width: auto;
  text-align: center;
}
.loadingCheck {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
#adminFrame table {
  width: 680px;
}
#adminFrame table tr:hover {
  background-color: #fff;
  cursor: default;
}
#adminFrame .btn {
  flex: 0 0 auto;
  margin-top: 1rem;
  width: 100px;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  color: #178;
  border: 1px solid #178;
  margin-right: 1rem;
}
#adminFrame .btn.primary {
  background-color: #dc712a;
  border-color: #cf6a27;
  color: #fff;
}
#adminFrame .btn:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}
#adminFrame .btn:hover.primary {
  background-color: #e08040;
  border-color: #d97736;
}
#adminFrame .btn svg {
  margin-right: 0.5rem;
}